
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'ContactsImportDeleteModal',
  components: {
    TmButton,
    TmModal,
  },
  props: {
    name: {
      type: Array as PropType<string[]>,
      default: () => [''],
    },
  },
})
